<template>
  <page-base v-loading="loading">
    <div class="data-list">
      <div class="data-item" v-for="item in dataList" :key="item.id" @click="chooeseIt(item)">
        <div style="display:flex;"><span class="data-label">{{getTitle(item)}}</span><modify-apply-label style="margin-left:8px;" :dataStatus="item.dataStatus"></modify-apply-label></div>
        <i class="iconfont icon-jiantouyou"></i>
      </div>
      <div class="add-btn" @click="chooeseIt(null)">+添加{{blockName}}</div>
    </div>
    <form-dialog
      class="mab-fd"
      v-if="openForm"
      :sourceInfo="chooseData && chooseData.sourceInfo ? chooseData.sourceInfo : null"
      :dataFileParm="dataFileParm"
      :data-type="dataType"
      :editData="chooseData ? (chooseData.editInfo || chooseData.sourceInfo) : null"
      :edit="chooseData ? chooseData.edit : null"
      :modifyApplyInfo="chooseData ? chooseData.modifyApplyInfo : null"
      :modifyApply="chooseData ? chooseData.modifyApply : null"
      :waitKeys="chooseData && chooseData.modifyApply && chooseData.modifyApply.newData ? Object.keys(JSON.parse(chooseData.modifyApply.newData)) : []"
      :onChange="onChange"
      @save="save"
      @submit="submit"
      :formParms="formParmsAdd"
      :workerId="workerId"></form-dialog>
  </page-base>
</template>

<script>
import PageBase from './pageBase'

import ModifyApplyLabel from './modifyApplyLabel'

import formDialog from './formDialog'

import {
  dataAppleRequest
} from '@/api'

export default {
  components: {
    PageBase,
    ModifyApplyLabel,
    formDialog
  },
  props: {
    isAbsolut: {
      type: Boolean,
      default: true
    },
    onChange: {
      type: Function
    },
    getEdit: {
      type: Function
    },
    saveEdit: {
      type: Function
    },
    addEdit: {
      type: Function
    },
    dataFileParm: {
      type: Object
    },
    initAddFormData: {
      type: Function
    },
    formParmsAdd: {
      type: Array
    },
    getTitle: {
      type: Function
    },
    dealDataInfo: {
      type: Function
    },
    blockName: {
      type: String
    },
    dataType: {
      type: String
    },
    workerId: {
      type: [String, Number]
    }
  },
  created() {
    this.load()
  },
  beforeRouteLeave (to, from, next) {
    if (this.openForm) {
      this.openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async load () {
      this.loading = true
      this.formDataList = await this.getEdit()
      this.loadData()
      this.loading = false
    },
    async save (parm, changed, isSubmit, isFile) {
      if (!isSubmit) {
        if (!changed) {
          this.$notice.info({
            title: '系统提示',
            desc: '无数据更改'
          })
          return
        }
      }
      this.loading = true
      if (changed) {
        if (!parm.id) {
          await this.addEdit(parm)
        } else {
          await this.saveEdit(parm)
        }
      }
      if (!isSubmit) {
        this.load()
        this.loading = false
        this.$notice.info({
          title: '系统提示',
          desc: '保存成功'
        })
        if (!isFile) {
          this.openForm = false
        }
      }
    },
    async submit (parm, changed) {
      this.loading = true
      await this.save(parm, changed, 'submit')
      await dataAppleRequest.submitByWorkerId(this.workerId, {
        dataType: this.dataType
      })
      this.load()
      this.$notice.info({
        title: '系统提示',
        desc: '提交成功'
      })
      this.openForm = false
      this.loading = false
    },
    chooeseIt (chooseData) {
      this.chooseData = chooseData ? this.formDataList.find(v => v.sourceInfo.id === chooseData.id) : null
      if (!this.chooseData) {
        this.chooseData = {
          editInfo: this.initAddFormData ? this.initAddFormData() : this.initAddFormDataNorm()
        }
        for (const item of this.formParmsAdd) {
          if (!this.chooseData.editInfo[item.key] && ['datePicker', 'datetimePicker'].includes(item.type)) {
            this.chooseData.editInfo[item.key] = new Date()
          }
        }
      }
      this.openForm = true
    },
    initAddFormDataNorm () {
      return {
        workerId: this.workerId
      }
    },
    loadData () {
      let data = this.formDataList.map((v) => {
        let dataItem = v.editInfo || v.sourceInfo
        dataItem.dataStatus = {}
        if (v.edit && v.edit.id) {
          if (v.editInfo.isDel) {
            dataItem.dataStatus.edit = 'del'
          } else if (v.sourceInfo.dataStatus === 'edit') {
            dataItem.dataStatus.edit = 'add'
          } else {
            dataItem.dataStatus.edit = 'edit'
          }
        }
        if (v.modifyApply) {
          dataItem.dataStatus.modifyApply = v.modifyApply.type
        }
        return dataItem
      })
      this.dataList = data
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      formDataList: [],
      dataList: [],
      openForm: false
    }
  }
}
</script>

<style lang="less">
.mab-fd {
  .header {
    display: none;
  }
  .form-page {
    height: calc(100% - 66px) !important;
  }
}
</style>

<style lang="less" scoped>
.mab-fd {
  position: fixed;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
}
.data-list {
  margin: 18px 25px 0;
  width: calc(100% - 110px);
  background: #fff;
  border-radius: 15px;
  padding: 11px 30px;
  overflow-y: auto;
  box-shadow: 0px 4px 30px rgba(232, 120, 145, 0.15);
  .data-item {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    .icon-jiantouyou {
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
    }
  }
  .data-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    max-width: 40vw;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .add-btn {
    height: 32px;
    background: #B2B2B2;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 88px;
    margin-top: 40px;
  }
}
</style>